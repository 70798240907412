import React, { useState } from "react"
import { Layout } from "@/layout"
import { Box, SEO, Heading, Text, Width, Span, CTA, Button, OptinModal, VideoPlayer } from "@/components/index"
import { color } from "@/theme/index"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import localStorage from "@/utils/localStorage"

export default function Invite() {
  const [modalOpened, setModalOpened] = useState(false)

  const existingDate = localStorage.get("dateText")
  let expirationDate = existingDate ? existingDate : ""
  if (!existingDate) {
    let date = new Date()
    date.setDate(date.getDate() + 7)
    let month = date.getMonth() + 1 // 10 (PS: +1 since Month is 0-based)
    let day = date.getDate() // 30
    let year = date.getFullYear()
    let dateString = month + "/" + day + "/" + year
    localStorage.set("dateText", dateString)
    expirationDate = dateString
  }
  const location = useLocation()
  const urlParameters =
    typeof window !== "undefined" ? queryString.parseUrl(location.search) : ""
  if (urlParameters.query?.rid) {
    localStorage.set("referrer_id", urlParameters.query.rid)
  }
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="$200 off Tuition - Invitation"
        description="Claim your invitation to Sonora Guitar Intensive"
      />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={"9.6rem"}
        pb={["4.8rem", null, null, "0"]}
      >
        <Heading level="1" mt="5rem" mb="2rem">
          🎉 TrueFire has invited you to Sonora! 🎉
        </Heading>
        <Width
          display="flex"
          flexDirection={["column", "row"]}
          pt={["0", null, null, "2.2rem"]}
          pb={["0", null, null, "13.9rem"]}
        >
          <Box
            width={["100%", null, 6 / 12]}
            mx="auto"
            textAlign="center"
            mb={["6rem", null, null, "0"]}
            px={["2.4rem", "0"]}
          >
            <Text mb="2.3rem">
              This invite gives you{" "}
              <Span fontWeight="600" color="amethyst">
                $200 Off
              </Span>{" "}
              and it expires on{" "}
              <Span fontWeight="600" color="amethyst">
                {expirationDate}
              </Span>{" "}
            </Text>

            <Box
              width={["100%", "80%", 12 / 12, null]}
              p="2.4rem"
              textAlign="center"
              mb={"2.4rem"}
              bg={color.opal}
              borderRadius=".6rem"
              ml={["auto", null, 0, null]}
              mr={["auto", null, "1.8rem", null]}
            >
              <Box
                display="flex"
                mb="0.6rem"
                alignItems="center"
                justifyContent={"center"}
              >
                <Text fontWeight="bold" color="amethyst">
                  Claim your Invitation
                </Text>
              </Box>
              <Text level={2}>
                If you're already familiar with the program and you’re ready to
                apply, click “Apply now”. Your discount will be applied upon
                acceptance into the program.
              </Text>
              
              <Button
                  variant="special"
                  onClick={() => setModalOpened(true)}
                  mb="3rem"
                  mt="1.2rem"
                >
                  Claim Invitation
                </Button>
              
            </Box>
            <Box
              width={["100%", "80%", 12 / 12, null]}
              p="2.4rem"
              textAlign="center"
              mb={"2.4rem"}
              bg="#FFF7F4"
              borderRadius=".6rem"
              ml={["auto", null, 0, null]}
              mr={["auto", null, "1.8rem", null]}
            >
              <Box
                display="flex"
                mb="0.6rem"
                alignItems="center"
                justifyContent={"center"}
              >
                <Text fontWeight="bold" color="amethyst">
                  What is Sonora Guitar Intensive?
                </Text>
              </Box>
              <VideoPlayer
                width="auto"
                maxWidth="auto"
                url="https://vimeo.com/483770837/9c16c5c33b"
            />
              <Text level={2} mt="0.6rem">
                At Sonora Guitar Intensive, we've helped over a thousand players
                break through the <strong>intermediate guitar plateau</strong>{" "}
                in a simple and systematic way, under the direct instruction of
                some of the <strong>best guitar players in the world.</strong>
              </Text>
            </Box>
          </Box>
        </Width>
        {modalOpened && (
          <OptinModal CTA="Claim" headerTwo="Claim Your Invite" navigateTo="/truefire/application" closeModal={() => setModalOpened(false)}></OptinModal>
        )}
      </Box>
      
    </Layout>
    
  )
}
